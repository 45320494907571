import adhesiveBandage from '../assets/emoji_iconpack/adhesive_bandage.png'
import alienMonster from '../assets/emoji_iconpack/alien_monster.png'
import astronaut from '../assets/emoji_iconpack/astronaut.png'
import automobile from '../assets/emoji_iconpack/automobile.png'
import balanceScale from '../assets/emoji_iconpack/balance_scale.png'
import banknoteDollar from '../assets/emoji_iconpack/banknote_dollar.png'
import banknoteEuro from '../assets/emoji_iconpack/banknote_euro.png'
import barberPole from '../assets/emoji_iconpack/barber_pole.png'
import beerMug from '../assets/emoji_iconpack/beer_mug.png'
import bellshop from '../assets/emoji_iconpack/bellshop.png'
import blueBook from '../assets/emoji_iconpack/blue_book.png'
import brain from '../assets/emoji_iconpack/brain.png'
import brick from '../assets/emoji_iconpack/brick.png'
import briefcase from '../assets/emoji_iconpack/briefcase.png'
import buildingConstruction from '../assets/emoji_iconpack/building_construction.png'
import calendar from '../assets/emoji_iconpack/calendar.png'
import cameraWithFlash from '../assets/emoji_iconpack/camera_with_flash.png'
import cannedFood from '../assets/emoji_iconpack/canned_food.png'
import cardFileBox from '../assets/emoji_iconpack/card_file_box.png'
import checkMark from '../assets/emoji_iconpack/check_mark.png'
import checkMarkButton from '../assets/emoji_iconpack/check_mark_button.png'
import child from '../assets/emoji_iconpack/child.png'
import clipboard from '../assets/emoji_iconpack/clipboard.png'
import cloudWithRain from '../assets/emoji_iconpack/cloud_with_rain.png'
import coin from '../assets/emoji_iconpack/coin.png'
import collision from '../assets/emoji_iconpack/collision.png'
import computerDisk from '../assets/emoji_iconpack/computer_disk.png'
import construction from '../assets/emoji_iconpack/construction.png'
import constructionWorker from '../assets/emoji_iconpack/construction_worker.png'
import cook from '../assets/emoji_iconpack/cook.png'
import couchAndLamp from '../assets/emoji_iconpack/couch_and_lamp.png'
import coupleWithHeart from '../assets/emoji_iconpack/couple_with_heart.png'
import crayon from '../assets/emoji_iconpack/crayon.png'
import creditCard from '../assets/emoji_iconpack/credit_card.png'
import crown from '../assets/emoji_iconpack/crown.png'
import deliveryTruck from '../assets/emoji_iconpack/delivery_truck.png'
import derelictHouse from '../assets/emoji_iconpack/derelict_house.png'
import dove from '../assets/emoji_iconpack/dove.png'
import downcastSweat from '../assets/emoji_iconpack/downcast_sweat.png'
import droplet from '../assets/emoji_iconpack/droplet.png'
import electricPlug from '../assets/emoji_iconpack/electric_plug.png'
import envelope from '../assets/emoji_iconpack/envelope.png'
import eyes from '../assets/emoji_iconpack/eyes.png'
import faceWithCrossedOutEyes from '../assets/emoji_iconpack/face_with_crossed_out_eyes.png'
import faceWithHeadBandage from '../assets/emoji_iconpack/face_with_head_bandage.png'
import faceWithThermometer from '../assets/emoji_iconpack/face_with_thermometer.png'
import factoryWorker from '../assets/emoji_iconpack/factory_worker.png'
import fearfulFace from '../assets/emoji_iconpack/fearful_face.png'
import fire from '../assets/emoji_iconpack/fire.png'
import fireExtinguisher from '../assets/emoji_iconpack/fire_extinguisher.png'
import firefighter from '../assets/emoji_iconpack/firefighter.png'
import firstPlaceMedal from '../assets/emoji_iconpack/first_place_medal.png'
import folder from '../assets/emoji_iconpack/folder.png'
import france from '../assets/emoji_iconpack/france.png'
import frenchFries from '../assets/emoji_iconpack/french_fries.png'
import funeralUrn from '../assets/emoji_iconpack/funeral_urn.png'
import gameDice from '../assets/emoji_iconpack/game dice.png'
import gemStone from '../assets/emoji_iconpack/gem_stone.png'
import glasses from '../assets/emoji_iconpack/glasses.png'
import globeShowingAmericas from '../assets/emoji_iconpack/globe_showing_americas.png'
import greenApple from '../assets/emoji_iconpack/green_apple.png'
import greenBook from '../assets/emoji_iconpack/green_book.png'
import guard from '../assets/emoji_iconpack/guard.png'
import hammerAndWrench from '../assets/emoji_iconpack/hammer_and_wrench.png'
import handshake from '../assets/emoji_iconpack/handshake.png'
import highVoltage from '../assets/emoji_iconpack/high-voltage.png'
import homework from '../assets/emoji_iconpack/homework.png'
import hotBeverage from '../assets/emoji_iconpack/hot_beverage.png'
import hourglassNotDone from '../assets/emoji_iconpack/hourglass_not_done.png'
import house from '../assets/emoji_iconpack/house.png'
import kiss from '../assets/emoji_iconpack/kiss.png'
import laptop from '../assets/emoji_iconpack/laptop.png'
import ledger from '../assets/emoji_iconpack/ledger.png'
import lightBulb from '../assets/emoji_iconpack/light_bulb.png'
import locked from '../assets/emoji_iconpack/locked.png'
import lockedWithKey from '../assets/emoji_iconpack/locked_with_key.png'
import lotus from '../assets/emoji_iconpack/lotus.png'
import magicWand from '../assets/emoji_iconpack/magic_wand.png'
import manHealthWorker from '../assets/emoji_iconpack/man_health_worker.png'
import manJudge from '../assets/emoji_iconpack/man_judge.png'
import manRaisingHand from '../assets/emoji_iconpack/man_raising_hand.png'
import manTechnologist from '../assets/emoji_iconpack/man_technologist.png'
import mechanicalArm from '../assets/emoji_iconpack/mechanical_arm.png'
import mobilePhone from '../assets/emoji_iconpack/mobile_phone.png'
import moneyBag from '../assets/emoji_iconpack/money_bag.png'
import moneyMouthFace from '../assets/emoji_iconpack/money_mouth-face.png'
import nauseatedFace from '../assets/emoji_iconpack/nauseated_face.png'
import newspaper from '../assets/emoji_iconpack/newspaper.png'
import ninja from '../assets/emoji_iconpack/ninja.png'
import notebook from '../assets/emoji_iconpack/notebook.png'
import notebookWithDecorativeCover from '../assets/emoji_iconpack/notebook_with_decorative_cover.png'
import nutAndBolt from '../assets/emoji_iconpack/nut_and_bolt.png'
import officeBuilding from '../assets/emoji_iconpack/office_building.png'
import olderPerson from '../assets/emoji_iconpack/older_person.png'
import openBook from '../assets/emoji_iconpack/open_book.png'
import orangeBook from '../assets/emoji_iconpack/orange_book.png'
import packageBox from '../assets/emoji_iconpack/package.png'
import pageWithCurl from '../assets/emoji_iconpack/page_with_curl.png'
import pancakes from '../assets/emoji_iconpack/pancakes.png'
import person from '../assets/emoji_iconpack/person.png'
import personFeedingBaby from '../assets/emoji_iconpack/person_feeding_baby.png'
import personGettingMassage from '../assets/emoji_iconpack/person_getting_massage.png'
import personInBed from '../assets/emoji_iconpack/person_in_bed.png'
import personInManualWheelchair from '../assets/emoji_iconpack/person_in_manual_wheelchair.png'
import personWhiteHair from '../assets/emoji_iconpack/person_white_hair.png'
import pill from '../assets/emoji_iconpack/pill.png'
import pilot from '../assets/emoji_iconpack/pilot.png'
import pirateFlag from '../assets/emoji_iconpack/pirate_flag.png'
import pizza from '../assets/emoji_iconpack/pizza.png'
import potOfFood from '../assets/emoji_iconpack/pot_of_food.png'
import pottedPlant from '../assets/emoji_iconpack/potted_plant.png'
import punch from '../assets/emoji_iconpack/punch.png'
import purse from '../assets/emoji_iconpack/purse.png'
import radioactive from '../assets/emoji_iconpack/radioactive.png'
import receipt from '../assets/emoji_iconpack/receipt.png'
import recycle from '../assets/emoji_iconpack/recycle.png'
import redBook from '../assets/emoji_iconpack/red_book.png'
import rocket from '../assets/emoji_iconpack/rocket.png'
import safetyVest from '../assets/emoji_iconpack/safety_vest.png'
import salad from '../assets/emoji_iconpack/salad.png'
import scientist from '../assets/emoji_iconpack/scientist.png'
import screamingFace from '../assets/emoji_iconpack/screaming_face.png'
import screwdriver from '../assets/emoji_iconpack/screwdriver.png'
import seeding from '../assets/emoji_iconpack/seeding.png'
import shield from '../assets/emoji_iconpack/shield.png'
import sneezingFace from '../assets/emoji_iconpack/sneezing_face.png'
import speechBalloon from '../assets/emoji_iconpack/speech-balloon.png'
import starStruck from '../assets/emoji_iconpack/star_struck.png'
import student from '../assets/emoji_iconpack/student.png'
import sushi from '../assets/emoji_iconpack/sushi.png'
import sweatDroplet from '../assets/emoji_iconpack/sweat_droplet.png'
import syringe from '../assets/emoji_iconpack/syringe.png'
import taxi from '../assets/emoji_iconpack/taxi.png'
import technologist from '../assets/emoji_iconpack/technologist.png'
import telephone from '../assets/emoji_iconpack/telephone.png'
import thumbUp from '../assets/emoji_iconpack/thumb_up.png'
import toothbrush from '../assets/emoji_iconpack/toothbrush.png'
import tornado from '../assets/emoji_iconpack/tornado.png'
import tubeTest from '../assets/emoji_iconpack/tube_test.png'
import umbrella from '../assets/emoji_iconpack/umbrella.png'
import umbrellaOnGround from '../assets/emoji_iconpack/umbrella_on_ground.png'
import umbrellaWithRainDrops from '../assets/emoji_iconpack/umbrella_with_rain_drops.png'
import volcano from '../assets/emoji_iconpack/volcano.png'
import waving from '../assets/emoji_iconpack/waving.png'
import wheelchair from '../assets/emoji_iconpack/wheelchair.png'
import window from '../assets/emoji_iconpack/window.png'
import womanRaisingHand from '../assets/emoji_iconpack/woman_raising_hand.png'
import womanSwimming from '../assets/emoji_iconpack/woman_swimming.png'
import womanTechnologist from '../assets/emoji_iconpack/woman_technologist.png'
import wrench from '../assets/emoji_iconpack/wrench.png'
import zipperMouthFace from '../assets/emoji_iconpack/zipper_mouth_face.png'

export const emoji = {
  adhesiveBandage,
  alienMonster,
  astronaut,
  automobile,
  balanceScale,
  banknoteDollar,
  banknoteEuro,
  barberPole,
  beerMug,
  bellshop,
  blueBook,
  brain,
  brick,
  briefcase,
  buildingConstruction,
  calendar,
  cameraWithFlash,
  cannedFood,
  cardFileBox,
  checkMark,
  checkMarkButton,
  child,
  clipboard,
  cloudWithRain,
  coin,
  collision,
  computerDisk,
  construction,
  constructionWorker,
  cook,
  couchAndLamp,
  coupleWithHeart,
  crayon,
  creditCard,
  crown,
  deliveryTruck,
  derelictHouse,
  dove,
  downcastSweat,
  droplet,
  electricPlug,
  envelope,
  eyes,
  faceWithCrossedOutEyes,
  faceWithHeadBandage,
  faceWithThermometer,
  factoryWorker,
  fearfulFace,
  fire,
  fireExtinguisher,
  firefighter,
  firstPlaceMedal,
  folder,
  france,
  frenchFries,
  funeralUrn,
  gameDice,
  gemStone,
  glasses,
  globeShowingAmericas,
  greenApple,
  greenBook,
  guard,
  hammerAndWrench,
  handshake,
  highVoltage,
  homework,
  hotBeverage,
  hourglassNotDone,
  house,
  kiss,
  laptop,
  ledger,
  lightBulb,
  locked,
  lockedWithKey,
  lotus,
  magicWand,
  manHealthWorker,
  manJudge,
  manRaisingHand,
  manTechnologist,
  mechanicalArm,
  mobilePhone,
  moneyBag,
  moneyMouthFace,
  nauseatedFace,
  newspaper,
  ninja,
  notebook,
  notebookWithDecorativeCover,
  nutAndBolt,
  officeBuilding,
  olderPerson,
  openBook,
  orangeBook,
  packageBox,
  pageWithCurl,
  pancakes,
  person,
  personFeedingBaby,
  personGettingMassage,
  personInBed,
  personInManualWheelchair,
  personWhiteHair,
  pill,
  pilot,
  pirateFlag,
  pizza,
  potOfFood,
  pottedPlant,
  punch,
  purse,
  radioactive,
  receipt,
  recycle,
  redBook,
  rocket,
  safetyVest,
  salad,
  scientist,
  screamingFace,
  screwdriver,
  seeding,
  shield,
  sneezingFace,
  speechBalloon,
  starStruck,
  student,
  sushi,
  sweatDroplet,
  syringe,
  taxi,
  technologist,
  telephone,
  thumbUp,
  toothbrush,
  tornado,
  tubeTest,
  umbrella,
  umbrellaOnGround,
  umbrellaWithRainDrops,
  volcano,
  waving,
  wheelchair,
  window,
  womanRaisingHand,
  womanSwimming,
  womanTechnologist,
  wrench,
  zipperMouthFace,
} as const
