import { css } from '@emotion/react'
import { memo, useCallback, useMemo, type ReactNode } from 'react'
import { AvatarDecorative, Text } from '../../../atoms/index.js'
import { Features, TabBar, type Feature } from '../../../molecules/index.js'
import { LegacyDialog } from '../../../templates/index.js'

import {
  X0,
  amountToString,
  gt,
  rateGt,
  rateToPercentString,
  zeroAmount,
  type Amount,
  type FinancialRate,
} from '@orus.eu/amount'
import { globalMonthlyCommitmentPenaltyTenPercentRate, type ProductAttributes } from '@orus.eu/dimensions'
import { spacing } from '../../../../foundation/spacing-tokens.js'
import { useScreenType } from '../../../../hooks/index.js'
import {
  projectAttributeInQuoteDetailDialog,
  useProductsAttributesProjection,
} from '../../../../hooks/use-product-attributes-projection.js'
import { useTranslate } from '../../../../localization/language-context.js'
import { Button } from '../../../button/index.js'
import { DetailLines } from '../detail-lines/index.js'

export type PricingDetailsDialogProps = {
  monthlyPrice: Amount
  yearlyPrice: Amount
  paymentRecurrence: 'monthly' | 'yearly'
  forbiddenMonthlyPayment: boolean
  commitment: 'monthly' | 'yearly'
  yearlyCommitmentHasDiscount: boolean
  /** The Orus discount */
  discount: FinancialRate
  /** The first period price. E.g., when paying monthly, the first month might include terrorism tax */
  firstPeriodPrice?: Amount
  /** The yearly terrorism tax. If it equals 0, it won't be shown */
  yearlyTerrorismTaxPrice?: Amount
  productsAttributes: ProductAttributes[]
  partnerApplicationPrice?: Amount

  onClose: () => void
  /**
   * When provided, a tab bar to select change the payment recurrence is added
   */
  onPaymentRecurrenceChange?: (paymentRecurrence: 'monthly' | 'yearly') => void
  /**
   * An arbitrary react node that can be inserted at the end of the dialog
   */
  footer?: ReactNode
  isPartner?: boolean
}

export const PricingDetailsDialog = memo<PricingDetailsDialogProps>(function PricingDetailsDialog(props) {
  const translate = useTranslate()
  const {
    monthlyPrice,
    yearlyPrice,
    paymentRecurrence,
    forbiddenMonthlyPayment,
    commitment,
    yearlyCommitmentHasDiscount,
    discount,
    firstPeriodPrice,
    yearlyTerrorismTaxPrice,
    productsAttributes,
    onClose,
    onPaymentRecurrenceChange,
    footer,
    isPartner,
    partnerApplicationPrice,
  } = props

  const screenType = useScreenType()

  const yearlyDiscountEnabled =
    yearlyCommitmentHasDiscount && paymentRecurrence === 'yearly' && !forbiddenMonthlyPayment
  const hasTerrorismTax = yearlyTerrorismTaxPrice && gt(yearlyTerrorismTaxPrice, zeroAmount)
  const formattedCurrentRecurrencePrice = amountToString(paymentRecurrence === 'monthly' ? monthlyPrice : yearlyPrice, {
    addCurrency: true,
  })
  const otherRecurrencePrice = paymentRecurrence === 'monthly' ? yearlyPrice : monthlyPrice
  const otherRecurrence = paymentRecurrence === 'monthly' ? 'yearly' : 'monthly'
  const tabs = useMemo(() => {
    return {
      monthly: translate('monthly'),
      yearly: `${translate('yearly')} ${
        yearlyCommitmentHasDiscount ? ` -${rateToPercentString(globalMonthlyCommitmentPenaltyTenPercentRate, 0)}` : ''
      }`,
    }
  }, [yearlyCommitmentHasDiscount, translate])

  const features = useMemo<Feature[]>(() => {
    return [
      {
        title: translate('subscription_funnel_payment_details'),
        content: (
          <>
            {translate(`subscription_funnel_payment_details_recurrence_${paymentRecurrence}`)}
            <br />
            <br />
            {formattedCurrentRecurrencePrice} {translate(`per_time_unit_${paymentRecurrence}`)}
            {!forbiddenMonthlyPayment && (
              <>
                <br />
                {translate(`or_amount_per_time_unit_${otherRecurrence}`, { amount: otherRecurrencePrice })}
              </>
            )}
          </>
        ),
      },
      ...(firstPeriodPrice
        ? [
            {
              title: translate(`subscription_funnel_payment_detail_first_payement_${paymentRecurrence}`),
              content: [
                amountToString(firstPeriodPrice, { addCurrency: true }),
                partnerApplicationPrice && gt(partnerApplicationPrice, zeroAmount)
                  ? translate('subscription_funnel_payment_detail_including_setup_fee', {
                      amount: partnerApplicationPrice,
                    })
                  : undefined,
              ]
                .filter(Boolean)
                .join(', '),
            },
          ]
        : []),
      {
        title: translate('subscription_funnel_payment_detail_commitment'),
        content: translate(
          commitment === 'yearly'
            ? 'subscription_funnel_with_commitment_title'
            : 'subscription_funnel_without_commitment_title',
        ),
      },
      ...(yearlyDiscountEnabled || rateGt(discount, X0)
        ? [
            {
              title: 'Avantage',
              content: [
                rateGt(discount, X0) ? `-${rateToPercentString(discount, 0)} de réduction code promotion` : undefined,
                yearlyDiscountEnabled
                  ? `-${rateToPercentString(globalMonthlyCommitmentPenaltyTenPercentRate, 0)} avantage paiement annuel`
                  : undefined,
              ]
                .filter(Boolean)
                .join(', '),
            },
          ]
        : []),
      {
        title: translate('subscription_funnel_payment_detail_taxes'),
        content: [
          translate('subscription_funnel_payment_detail_no_vat'),
          hasTerrorismTax
            ? `taxe attentat annuelle de ${amountToString(yearlyTerrorismTaxPrice, { addCurrency: true })}`
            : undefined,
        ]
          .filter(Boolean)
          .join(', '),
      },
    ]
  }, [
    paymentRecurrence,
    formattedCurrentRecurrencePrice,
    forbiddenMonthlyPayment,
    otherRecurrencePrice,
    firstPeriodPrice,
    commitment,
    yearlyDiscountEnabled,
    discount,
    partnerApplicationPrice,
    hasTerrorismTax,
    yearlyTerrorismTaxPrice,
    translate,
    otherRecurrence,
  ])

  const handleTabChange = useCallback(
    (tabId: string) => {
      if ((tabId !== 'monthly' && tabId !== 'yearly') || !onPaymentRecurrenceChange) return
      onPaymentRecurrenceChange(tabId)
    },
    [onPaymentRecurrenceChange],
  )
  const projectedProductsAttributes = useProductsAttributesProjection(
    productsAttributes,
    projectAttributeInQuoteDetailDialog,
  )

  return (
    <LegacyDialog
      onClose={onClose}
      header={
        <div>
          <AvatarDecorative icon="list-check-regular" backgroundColor="sky" size="60" />
          <Text
            variant="h4"
            css={css`
              margin-top: ${spacing[50]};
            `}
          >
            {translate('subscription_funnel_quote_summary')}
          </Text>
        </div>
      }
      fullWidth={true}
    >
      <div>
        <Text variant="subtitle2">
          {translate(isPartner ? 'subscription_funnel_the_contribution' : 'subscription_funnel_your_contribution')}
        </Text>

        {onPaymentRecurrenceChange ? (
          <TabBar
            fullWidth={screenType === 'mobile'}
            size="large"
            tabs={tabs}
            selectedTabId={paymentRecurrence}
            onTabChange={handleTabChange}
            css={css`
              margin-top: ${spacing[50]};
            `}
          />
        ) : (
          <></>
        )}

        <Features features={features} />

        {projectedProductsAttributes.length > 0 ? (
          <>
            <Text
              variant="subtitle2"
              css={css`
                margin-top: ${spacing[60]};
              `}
            >
              {translate(isPartner ? 'the_formula' : 'your_formula')}
            </Text>
            <DetailLines
              projectedProductsAttributes={projectedProductsAttributes}
              css={css`
                margin-top: ${spacing[50]};
              `}
            />
          </>
        ) : (
          <></>
        )}

        {footer ? footer : <DefaultFooter onClose={onClose} />}
      </div>
    </LegacyDialog>
  )
})

const DefaultFooter = memo<{ onClose: () => void }>(function DefaultFooter({ onClose }) {
  const screenType = useScreenType()

  return screenType === 'mobile' ? (
    <div
      css={css`
        display: flex;
        flex-direction: ${screenType === 'mobile' ? 'column' : 'row'};
        justify-content: flex-end;
      `}
    >
      <Button variant="secondary" size="large" onClick={onClose}>
        Fermer
      </Button>
    </div>
  ) : (
    <></>
  )
})
