import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { amountToString, rateGt, X0, type Amount, type FinancialRate } from '@orus.eu/amount'
import { globalMonthlyCommitmentPenaltyTenPercentRate, type ProductAttributes } from '@orus.eu/dimensions'
import { memo, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { colors } from '../../../../colors.js'
import { shadow } from '../../../../foundation/shadow-tokens.js'
import { spacing } from '../../../../foundation/spacing-tokens.js'
import {
  productAttributeValueToString,
  projectAttributeInSummaryUnderQuote,
  useProductsAttributesProjection,
} from '../../../../hooks/use-product-attributes-projection.js'
import { useLanguage, useTranslate } from '../../../../localization/language-context.js'
import { Text } from '../../../atoms/index.js'
import { Button } from '../../../button/index.js'
import { Chip } from '../../../chip/chip.js'
import { DownloadButton } from '../../../molecules/download-button.js'

type Variant = 'mobile' | 'desktop'

export type PricingBlockProps = {
  variant: Variant

  paymentRecurrence: 'monthly' | 'yearly'
  forbiddenMonthlyPayment: boolean
  monthlyPrice: Amount
  yearlyPrice: Amount
  explanationsAboveSubscriptionButton: string[]
  discount: FinancialRate
  yearlyCommitmentHasDiscount: boolean
  monthlyWithoutCommitment?: boolean

  productsAttributes: ProductAttributes[]

  onSeeMoreDetailsClick: () => void
  onSubscribeClick?: () => void
  onSubscribeButtonInView?: (inView: boolean) => void
  className?: string
  handleDownload: () => void
  downloadInProgress: boolean
}

export const PricingBlock = memo<PricingBlockProps>(function PricingBlock(props) {
  const language = useLanguage()
  const translate = useTranslate()
  const {
    variant,
    paymentRecurrence,
    forbiddenMonthlyPayment,
    monthlyPrice,
    yearlyPrice,
    explanationsAboveSubscriptionButton,
    discount,
    productsAttributes,
    yearlyCommitmentHasDiscount,
    monthlyWithoutCommitment,

    onSeeMoreDetailsClick,
    onSubscribeClick,
    onSubscribeButtonInView,
    className,

    handleDownload,
    downloadInProgress,
  } = props

  const { ref: subscribeButtonRef, inView: subscribeButtonInView } = useInView({ threshold: 0.5 })

  useEffect(() => onSubscribeButtonInView?.(subscribeButtonInView), [onSubscribeButtonInView, subscribeButtonInView])

  const currentRecurrencePrice = paymentRecurrence === 'monthly' ? monthlyPrice : yearlyPrice
  const formattedCurrentRecurrencePrice = amountToString(currentRecurrencePrice, AMOUNT_TO_STRING_OPTIONS)
  const otherRecurrencePrice = paymentRecurrence === 'monthly' ? yearlyPrice : monthlyPrice
  const otherRecurrence = paymentRecurrence === 'monthly' ? 'yearly' : 'monthly'

  const showWithoutCommitmentChip =
    paymentRecurrence === 'monthly' && monthlyWithoutCommitment && !forbiddenMonthlyPayment
  const showYearlyDiscountChip =
    paymentRecurrence === 'yearly' && !!yearlyCommitmentHasDiscount && !forbiddenMonthlyPayment

  const detailLines = useProductsAttributesProjection(productsAttributes, projectAttributeInSummaryUnderQuote)

  return (
    <Container variant={variant} className={className}>
      <Text variant="h4">{formattedCurrentRecurrencePrice}</Text>
      <Text variant="captionMedium">{translate(`per_time_unit_taxes_included_${paymentRecurrence}`)}</Text>
      {!!(showYearlyDiscountChip || showWithoutCommitmentChip || discount) && (
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: ${spacing[30]};
            justify-content: ${variant === 'mobile' ? 'center' : 'flex-start'};

            margin-top: ${spacing[50]};
          `}
        >
          {showWithoutCommitmentChip && (
            <Chip size="large" backgroundColor={colors.mindaro.dark} textColor={colors.green[900]}>
              {translate('subscription_funnel_without_commitment_badge')}
            </Chip>
          )}

          {showYearlyDiscountChip && (
            <Chip size="large" backgroundColor={colors.sky.dark} textColor={colors.green[900]}>
              {translate('subscription_funnel_yearly_discount_badge', {
                rate: globalMonthlyCommitmentPenaltyTenPercentRate,
              })}
            </Chip>
          )}

          {rateGt(discount, X0) && (
            <Chip size="large" backgroundColor={colors.jasmine.dark} textColor={colors.green[900]}>
              {translate('subscription_funnel_discount_badge', { rate: discount })}
            </Chip>
          )}
        </div>
      )}
      {!forbiddenMonthlyPayment && (
        <Text
          variant="body2Medium"
          color={colors.blue[900]}
          css={css`
            margin-top: ${spacing[30]};
          `}
        >
          {translate(`or_amount_per_time_unit_${otherRecurrence}`, { amount: otherRecurrencePrice })}
        </Text>
      )}
      <Button
        variant="text"
        icon="circle-info-regular"
        avatarPosition="left"
        onClick={onSeeMoreDetailsClick}
        css={css`
          align-self: ${variant === 'mobile' ? 'center' : 'flex-start'};
          margin-top: ${spacing[60]};
        `}
      >
        {translate('subscription_funnel_see_details')}
      </Button>
      {!!detailLines?.length && (
        <div>
          {detailLines.map((detailLine, index) => (
            <div
              key={index}
              css={css`
                margin-top: ${spacing[60]};
              `}
            >
              <Text
                variant="body2Medium"
                css={css`
                  text-align: left;
                `}
              >
                {detailLine.productName}
              </Text>
              {detailLine.attributes.map((attribute) => (
                <div
                  key={attribute.label}
                  css={css`
                    display: flex;
                    justify-content: space-between;
                  `}
                >
                  <Text
                    variant="body2"
                    css={css`
                      text-align: left;
                    `}
                  >
                    {attribute.label}
                  </Text>
                  <Text
                    variant="body2Medium"
                    css={css`
                      text-align: right;
                    `}
                    color={colors.blue[500]}
                  >
                    {productAttributeValueToString(attribute, language)}
                  </Text>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {onSubscribeClick && (
        <>
          {explanationsAboveSubscriptionButton.length > 0 ? (
            <div
              css={css`
                margin-top: ${spacing[70]};
                text-align: center;
              `}
            >
              {explanationsAboveSubscriptionButton.map((explanation, index) => (
                <Text key={index} variant="body2">
                  {explanation}
                </Text>
              ))}
            </div>
          ) : (
            <></>
          )}
          <Button
            size={variant === 'mobile' ? 'large' : 'medium'}
            onClick={onSubscribeClick}
            ref={subscribeButtonRef}
            css={css`
              margin-top: ${spacing[70]};
            `}
          >
            {translate('subscription_funnel_continue_for_amount', { amount: currentRecurrencePrice })}
          </Button>
        </>
      )}
      {!subscribeButtonInView && onSubscribeClick && (
        <Button
          size={variant === 'mobile' ? 'large' : 'medium'}
          onClick={onSubscribeClick}
          css={css`
            margin-top: ${spacing[70]};
            position: fixed;
            bottom: ${variant === 'mobile' ? '0px' : spacing[60]};
            right: ${variant === 'mobile' ? '0px' : spacing[60]};
            border-radius: ${variant === 'mobile' ? '0px' : 'none'};
          `}
          fullWidth={variant === 'mobile'}
        >
          {translate('subscription_funnel_continue_for_amount', { amount: currentRecurrencePrice })}
        </Button>
      )}
      <DownloadButton
        css={css`
          margin-top: ${spacing[40]};
        `}
        size={variant === 'mobile' ? 'large' : 'medium'}
        icon="arrow-down-to-line-solid"
        iconColor={colors.blue[900]}
        avatarPosition="left"
        variant="secondary"
        downloadInProgress={downloadInProgress}
        onClick={handleDownload}
      >
        {translate('subscription_funnel_download_quote_pdf')}
      </DownloadButton>
    </Container>
  )
})

const Container = styled.div<{ variant: Variant }>`
  display: flex;
  flex-direction: column;

  width: ${(props) => (props.variant === 'mobile' ? '100%' : '375px')};
  padding: ${spacing[70]};

  text-align: ${(props) => (props.variant === 'mobile' ? 'center' : 'left')};

  background-color: ${colors.white};
  border: ${(props) =>
    props.variant === 'mobile'
      ? 'none'
      : `
    1px solid ${colors.gray[100]};
  `};
  border-radius: ${(props) => (props.variant === 'mobile' ? '0px' : '12px')};
  box-shadow: ${(props) => (props.variant === 'mobile' ? 'none' : shadow.bottom[30])};
`
const AMOUNT_TO_STRING_OPTIONS = { addCurrency: true }
