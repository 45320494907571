import { css } from '@emotion/react'
import { memo } from 'react'

import { spacing } from '../../../foundation/spacing-tokens.js'
import { Avatar, type CompoundIconName } from '../../atoms/index.js'

export const PassthroughIcon = memo<{ className?: string; icon: CompoundIconName }>(function PassthroughInfoButton({
  className,
  icon,
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;

        padding: ${spacing[40]};
      `}
      className={className}
    >
      <Avatar size="30" icon={icon} />
    </div>
  )
})
