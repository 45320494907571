import { memo, type ChangeEvent, type ComponentProps } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Checkbox } from '@mui/material'

import { colors } from '../../../../colors.js'
import {
  borderRadius,
  shadow,
  spacing,
  useDialogVisibility,
  useScreenVariant,
  useTranslate,
} from '../../../../index.js'
import { secondaryColor } from '../../../../theme.js'
import { AvatarDecorative, Text } from '../../../atoms/index.js'
import { Button } from '../../../button/index.js'

import { ProductDetailsDialog } from './product-details-modal.js'

type ProductSelectorItemProps = {
  identifier: string
  isSelected: boolean
  toggleSelected: () => void
  setSelected: (_event: ChangeEvent<HTMLInputElement>, value: boolean) => void
  productLongName: string
  productShortDescription: string
  productAvatarConfiguration: Pick<ComponentProps<typeof AvatarDecorative>, 'icon' | 'backgroundColor'>
  productDetails: ComponentProps<typeof ProductDetailsDialog>['details'] | null
}
export const ProductSelectorItem = memo<ProductSelectorItemProps>(function ProductSelectorItem(props) {
  const translate = useTranslate()
  const {
    identifier,
    isSelected,
    toggleSelected,
    setSelected,
    productLongName,
    productShortDescription,
    productAvatarConfiguration,
    productDetails,
  } = props
  const { show, hide, visible } = useDialogVisibility(identifier)
  const screenVariant = useScreenVariant()

  return (
    <>
      <div
        css={css`
          display: flex;
          padding: ${screenVariant === 'desktop' ? spacing[60] : `${spacing[50]} ${spacing[60]}`};
          flex-direction: ${screenVariant === 'desktop' ? 'row' : 'column'};
          align-items: ${screenVariant === 'desktop' ? 'center' : 'flex-start'};
          gap: ${spacing[60]};
          border-radius: ${borderRadius[20]};
          border: 1px solid;
          border-color: ${isSelected ? secondaryColor : colors.gray[100]};
          outline: ${isSelected ? '2px solid' : 'none'} ${secondaryColor};
          outline-offset: ${isSelected ? '-2px' : 'none'};
          color: ${colors.blue[900]};
          background-color: ${colors.white};
          justify-content: space-between;
          transition: all 0.1s ease-in-out;
          cursor: pointer;

          &:hover {
            border-color: ${isSelected ? secondaryColor : colors.gray[300]};
            box-shadow: ${shadow.bottom[10]};
          }
        `}
        onClick={toggleSelected}
      >
        <div
          css={css`
            display: flex;
            flex-direction: ${screenVariant === 'desktop' ? 'row' : 'column'};
            gap: ${screenVariant === 'desktop' ? spacing[50] : spacing[30]};
            align-items: left;
          `}
        >
          <AvatarDecorative
            css={css`
              flex-shrink: 0;
            `}
            size={screenVariant === 'desktop' ? '60' : '50'}
            {...productAvatarConfiguration}
          ></AvatarDecorative>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing[20]};
            `}
          >
            <TitleText variant="body1Medium">{productLongName}</TitleText>
            <Text variant="body2">{productShortDescription}</Text>
          </div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            ${screenVariant === 'mobile' ? 'justify-content: space-between;' : 'justify-content: flex-end;'}
            gap: ${spacing[20]};
          `}
        >
          <Button variant="text" size="small" onClick={show}>
            {translate('subscription_funnel_product_indicator_learn_more')}
          </Button>
          <Checkbox checked={isSelected} onChange={setSelected} />
        </div>
      </div>
      {!!productDetails && visible && (
        <ProductDetailsDialog
          productId={productLongName}
          details={productDetails}
          fullWidth={'mobile' === screenVariant}
          hide={hide}
          avatar={productAvatarConfiguration}
          showGuaranteeAndOptions
        />
      )}
    </>
  )
})

const TitleText = styled(Text)()
