import { css } from '@emotion/react'
import { memo } from 'react'
import { colors } from '../../../../colors.js'
import {
  borderRadius,
  ButtonLink,
  spacing,
  useScreenVariant,
  useSkin,
  useTranslate,
  type ScreenVariant,
} from '../../../../index.js'
import { PhoneNumber, Text, TextContainer } from '../../../atoms/index.js'
import { OrusFaceAvatar } from '../../universal-subscription/index.js'

type OnContactClick = () => void

export type HelpBlockProps = {
  variant?: ScreenVariant
  onContactClick: OnContactClick
  className?: string
  orusContactPhone: string
}

export const HelpBlock = memo<HelpBlockProps>(function HelpBlock(props) {
  const translate = useTranslate()
  const screenVariant = useScreenVariant()
  const skin = useSkin()
  const { variant = screenVariant, orusContactPhone } = props

  return (
    <div
      css={css`
        background: ${colors.sky.gradient};
        padding: ${variant === 'mobile' ? spacing[70] : `${spacing[70]} 0 0 ${spacing[70]}`};
        border-radius: ${borderRadius[30]};
        display: flex;
        gap: ${variant === 'mobile' ? spacing[70] : spacing[70]};
      `}
    >
      <div
        css={css`
          flex: 1;
        `}
      >
        {variant === 'mobile' ? (
          <OrusFaceAvatar
            css={css`
              margin-bottom: ${spacing[40]};
            `}
            variant="round"
            size="60"
          />
        ) : (
          <></>
        )}
        <TextContainer color={skin.helpBlockTextColor}>
          <Text variant="subtitle1">{translate('subscription_need_help_title')}</Text>
          <Text
            variant="body2"
            css={css`
              margin-top: ${spacing[30]};
            `}
          >
            {translate('subscription_need_help_explanation')}
          </Text>
        </TextContainer>

        <div
          css={css`
            display: flex;
            gap: ${spacing[30]};
            flex-direction: ${variant === 'desktop' ? 'row' : 'column'};
            padding-bottom: ${variant === 'mobile' ? '0px' : `${spacing[70]}`};
            margin-top: ${spacing[70]};
          `}
        >
          <ButtonLink
            variant="primary"
            size={variant === 'mobile' ? 'large' : 'medium'}
            fullWidth={variant === 'mobile'}
            icon="phone-regular"
            avatarPosition="left"
            to={`tel:${orusContactPhone}`}
            trackingId="call_button"
          >
            <PhoneNumber>{orusContactPhone.replace(/^\+33 ?/, '0')}</PhoneNumber>
          </ButtonLink>

          <ButtonLink
            variant="tertiary"
            size={variant === 'mobile' ? 'large' : 'medium'}
            fullWidth={variant === 'mobile'}
            avatarPosition="left"
            icon="whatsapp-brands"
            target="_blank"
            to="https://wa.me/message/NVLHTZVDLQHHO1"
          >
            <Text variant="button" color={skin.helpBlockTextColor}>
              WhatsApp
            </Text>
          </ButtonLink>
        </div>
      </div>
      {variant === 'desktop' ? (
        <div
          css={css`
            flex: 1;

            display: flex;
            flex-direction: column;
            align-items: center;
            width: 258px;
            position: relative;
            overflow: hidden;

            container-type: size;
            border-bottom-right-radius: 12px;
          `}
        >
          <img
            src={skin.quoteHelpBannerImageUrl}
            alt=""
            css={css`
              width: 258px;
              position: absolute;

              @container (max-width: 258px) {
                align-self: flex-start;
              }
            `}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
})
