import { css } from '@emotion/react'
import { memo } from 'react'
import { Card, colorTokens, useScreenType, useTranslate } from '../../../../index.js'
import { ButtonLink, PhoneNumber } from '../../../index.js'
import { OrusFaceAvatar } from '../orus-face-avatar/index.js'

export type ContactBannerProps = {
  orusContactPhone: string
}

export const ContactBanner = memo<ContactBannerProps>(function ContactBanner(props) {
  const { orusContactPhone } = props
  const screenType = useScreenType()
  const translate = useTranslate()
  return (
    <Card
      title={translate('subscription_need_help_title')}
      subtitle={translate('subscription_need_help_explanation')}
      avatar={<OrusFaceAvatar variant="round" size="60" />}
      backgroundColor={colorTokens['color-bg-decorative-2']}
      fullwidth
    >
      <ButtonLink
        variant="primary"
        size={screenType === 'desktop' ? 'medium' : 'large'}
        fullWidth
        icon="phone-regular"
        avatarPosition="left"
        to={`tel:${orusContactPhone}`}
        trackingId="call_button"
      >
        <PhoneNumber>{orusContactPhone.replace(/^\+33 ?/, '0')}</PhoneNumber>
      </ButtonLink>

      <ButtonLink
        css={css`
          color: ${colorTokens['color-text-base-secondary']};
        `}
        variant="secondary"
        size={screenType === 'desktop' ? 'medium' : 'large'}
        fullWidth
        avatarPosition="left"
        icon="whatsapp-brands"
        target="_blank"
        to="https://wa.me/message/NVLHTZVDLQHHO1"
      >
        {translate('chat_on_whatsapp')}
      </ButtonLink>
    </Card>
  )
})
